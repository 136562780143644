import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Gif from "../../component/Gif/Gif";
import { MailIcon } from "../../component/Svg/Svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UserContext, useUserContext } from "../../utils/userContext";
import Register from "./Register";
import axiosClient from "../../utils/apiClient";

export default class Request extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      error: null,
      step: 1,
      phone: "1",
      number: null,
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    const style = document.documentElement.style;
    style.setProperty("--height", `${window.innerHeight}px`);
  }

  onChangeInput(e) {
    this.setState({ number: e });
    if (this.state.error) {
      this.setState({ error: null });
    }
  }

  validateExistingUser() {
    axiosClient
      .get(`find-user/${String(this.state.phone) + String(this.state.number)}`)
      .then((response) => {
        const user = response.data;
        // console.log(userobj, response.data);
        if (user) this.setState({ userData: response.data, step: 2 });
        else {
          this.setState({
            error: "Oh no! We cannot find your number in our system!",
          });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    // PWA: state
    const { showInstallModal } = this.state;

    return (
      <Fragment>
        {this.state.step === 1 ? (
          <div className="overflow-hidden bg__black wrapper__wrap-all-screan">
            <div className="h-100 position-relative d-flex">
              <Gif />

              <div className="position-relative z-5 w-100 wrapper__bg-first align-self-end">
                <div className="container w-100 py-4">
                  <div className="text-center mb-5">
                    <img src="./../images/dsads.png" className="mb-2" alt="" />
                    <h2 className="bold font__size--18 lh-1">
                      Welcome Back to CENTURION.
                    </h2>
                    <p className="normal font__size--14 mb-3">
                      Log in and access exclusive dining and lifestyle
                      experiences by entering your email below.
                    </p>

                    <div className="wrapper__form-input text-left">
                      <div className="number">
                        <div className="d-inline-block">
                          <div className="wrap">
                            <PhoneInput
                              className="color__black"
                              country={"us"}
                              enableSearch={true}
                              value={this.state.phone}
                              onChange={(phone) => this.setState({ phone })}
                            />
                            <div className="ml-0">
                              {this.state.phone ? "+" + this.state.phone : ""}
                            </div>
                          </div>
                        </div>
                        <input
                          type="number"
                          onChange={(e) => this.onChangeInput(e.target.value)}
                          className="ml-2 medium color__white font__size--16"
                          placeholder="Phone number"
                        />
                      </div>
                    </div>
                    {this.state.error && (
                      <p className="mb-0 color__red normal font__size--14">
                        {this.state.error}
                      </p>
                    )}
                  </div>
                  <button
                    // to="/register"
                    disabled={!this.state.number}
                    className="bold font__size--16 btn btn__tosca shadow color__black-2 w-100 rounded__20"
                    onClick={() => {
                      this.validateExistingUser();
                      //   const userobj = { full_name: "Sanu Shaw" };
                      //   localStorage.setItem("user", JSON.stringify(userobj));
                      //   this.context.setUser(userobj);
                    }}
                  >
                    Next
                  </button>

                  <div className="text-center mt-4">
                    <NavLink
                      to="/"
                      className="mb-0 normal font__size--16 color__gray-2 decoration"
                    >
                      Back to Home
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Register userData={this.state.userData} />
        )}
      </Fragment>
    );
  }
}
