import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Gif from "../../component/Gif/Gif";

export default class Thanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  componentDidMount() {
    const style = document.documentElement.style;
    style.setProperty("--height", `${window.innerHeight}px`);
  }

  render() {
    return (
      <Fragment>
        <div className="overflow-hidden bg__black wrapper__wrap-all-screan">
          <div className="h-100 position-relative d-flex">
            <Gif />

            <div className="wrapper__center-text">
              <div className="text-center">
                <img src="./../images/dsads.png" className="mb-2" alt="" />
                <h2 className="bold font__size--18 lh-1">
                  Thanks for Requesting an Invite!
                </h2>
                <p className="normal font__size--14">
                  We've received your invite request and will send you a sms as
                  soon as possible with instructions on how to get started.
                </p>
              </div>
            </div>

            <div className="position-relative z-5 w-100 wrapper__bg-first align-self-end">
              <div className="container w-100 py-4">
                <div className="text-center">
                  <NavLink
                    to="/"
                    className="mb-0 normal font__size--16 color__gray-2 decoration"
                  >
                    Back to Home
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
