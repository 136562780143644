import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { BackIcon } from '../../../../Svg/Svg';
import DatePicker from "react-datepicker";
import { format } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const SelectDate = (props) => {
    const [startDate, setStartDate] = useState("");
    const onChange = (dates) => {
        // const [start, end] = dates;
        setStartDate(dates);
    };

    useEffect(() => {
        props.setMakeRequest(startDate, "selectDate");
    }, [startDate]);
    return (
        <Fragment>
            <div className="text-center general date">
                <h4 className="bold font__size--18">When’s the Occasion?</h4>
                <p className="normal font__size--16 color__gray-2 mb-3">
                    Help us tailor your experience.
                </p>

                <div className="mb-4 dateFormat">
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        minDate={new Date()}
                        inline
                    />
                </div>

                <div className="d-flex align-items-center">
                    <div
                        onClick={() =>
                            props.dataState.typeChoose == 2
                                ? props.setActiveModal(2)
                                : props.setActiveModal(3)
                        }
                        className="arrow flex-shrink-0 pointer"
                    >
                        <BackIcon />
                    </div>
                    <div
                        onClick={() => (
                            props.setActiveModal(5),
                            props.updateReqData(
                                "date",
                                moment(startDate).format("DD-MM-yyyy")
                            )
                        )}
                        className={
                            "btn btn__tosca btn__select w-100 normal font__size--14 " +
                            (startDate ? "active" : "")
                        }
                    >
                        Next: <b>Time Selection</b>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SelectDate;
