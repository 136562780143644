import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Gif from "../../component/Gif/Gif";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  componentDidMount() {
    const style = document.documentElement.style;
    style.setProperty("--height", `${window.innerHeight}px`);

    let user = localStorage.getItem("user");
    if (user && Object.keys(user).length > 0) {
      this.props.history.push("/homepage");
    }
  }

  render() {
    return (
      <Fragment>
        <div className="overflow-hidden bg__black wrapper__wrap-all-screan">
          <div className="h-100 position-relative d-flex">
            <Gif />

            <div className="position-relative z-5 w-100 wrapper__bg-first align-self-end">
              <div className="container w-100 py-4">
                <div className="text-center mb-5">
                  <img src="./../images/dsads.png" className="mb-2" alt="" />
                  <h2 className="bold font__size--18 lh-1">
                    Join the CENTURION Experience
                  </h2>
                  <p className="normal font__size--14">
                    Ready to discover a world of exclusive dining and lifestyle
                    experiences? Request an invite or sign in to get started!
                  </p>
                </div>
                <NavLink
                  to="/request"
                  className="bold font__size--16 btn btn__tosca shadow color__black-2 w-100 rounded__20"
                >
                  Request an invite
                </NavLink>

                <div className="text-center mt-4">
                  <p className="mb-0 normal font__size--16">
                    Have an invite?{" "}
                    <NavLink to="/login" className="bold color__tosca">
                      Sign in
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Index);
