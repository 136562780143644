import React, { useEffect, useRef, useState } from "react";

const PhotoEdit = (props) => {
  const upload = useRef(null);
  const onClickUpload = (e) => {
    upload.current.click();
  };
  const onChangeHandle = (e) => {
    console.log(e.target.files[0]);
    props.setImg(e.target.files[0]);
  };

  console.log(props);
  return (
    <div className="wrapper__photo-profile position-relative mx-auto">
      <img
        src={
          props.img != null
            ? URL.createObjectURL(props.img)
            : props.profile_photo
            ? `${process.env.REACT_APP_BACKEND_URL}/api/getFile/${props.profile_photo}`
            : "./../images/e05ee2b9621a505cc4c3db1ec9cd1d95.png"
        }
        className="position-relative z-2"
        alt=""
      />
      <input
        ref={upload}
        type="file"
        onChange={onChangeHandle}
        accept="image/*"
      />
      <div className="edit z-3 pointer" onClick={onClickUpload}>
        <img src="./../images/photo.png" alt="" />
      </div>
    </div>
  );
};

export default PhotoEdit;
