import axios from 'axios';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}/api`;

axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
};

//All request will wait 2 seconds before timeout
// axiosClient.defaults.timeout = 2000;

export default axiosClient;
// export function getRequest(URL) {
//     return axiosClient.get(`/${URL}`).then(response => response);
// }

// export function postRequest(URL, payload) {
//     return axiosClient.post(`/${URL}`, payload).then(response => response);
// }