import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { LocationIcon, SearchIcon } from "../../../Svg/Svg";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

const Location = () => {
  const [Type, setType] = useState(2);
  const [Location, setLocation] = useState("");
  const [Search, setSearch] = useState("");

  // useEffect(() => {
  //     props.setMakeRequest(Location, "location")
  //     props.setMakeRequest(Search, "search")
  // }, [Location, Search])
  return (
    <Fragment>
      <div className="text-center general location">
        <h4 className="bold font__size--18">
          Where do you want a reservation?
        </h4>
        <p className="normal font__size--16 color__gray-2 mb-3">
          Tell us your ideal location.
        </p>

        <div className="mb-4">
          <div className="wrapper__filed-general">
            {/* <LocationIcon />
                        <input type="text" placeholder='Location' className='form-control medium font__size--14 color__white ml-2 w-100' onChange={(e) => setLocation(e.target.value)} /> */}
            <Autocomplete
              apiKey={process.env.GOOGLE_MAPS_API}
              style={{ width: "98%" }}
              onPlaceSelected={(place) => {
                console.log(place);
              }}
              options={{
                types: ["(regions)"],
                componentRestrictions: { country: "ru" },
              }}
              defaultValue="Amsterdam"
            />
          </div>
          {Type == 2 ? (
            <div className="wrapper__filed-general mt-3">
              {/* <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                className="form-control medium font__size--14 color__white ml-2 w-100"
                onChange={(e) => setSearch(e.target.value)}
              /> */}
              <Autocomplete
                apiKey={process.env.GOOGLE_MAPS_API}
                style={{ width: "98%" }}
                onPlaceSelected={(place) => {
                  console.log(place);
                }}
                options={{
                  types: ["(regions)"],
                  componentRestrictions: { country: "ru" },
                }}
                defaultValue="Amsterdam"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Location;
