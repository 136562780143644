import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Fragment } from 'react'

const Time = () => {
    const [Select, setSelect] = useState("")
    const [time, settime] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"])

    // useEffect(() => {
    //     props.setMakeRequest(Select, "selectTime")
    // }, [Select])
    return (
        <Fragment>
            <div className="text-center general time">
                <h4 className='bold font__size--18'>What will be the Time?</h4>
                <p className='normal font__size--16 color__gray-2 mb-5'>Select a time that suits your needs.</p>

                <div className="mb-5">
                    <div className="list__time mb-4">
                        {
                            time.map((obj) => [
                                <div className={"items " + (Select == obj + ":00 AM" ? "active" : "")} onClick={() => setSelect(obj + ":00 AM")}>{obj}:00 AM</div>
                            ])
                        }
                        {
                            time.map((obj) => [
                                <div className={"items " + (Select == obj + ":00 PM" ? "active" : "")} onClick={() => setSelect(obj + ":00 PM")}>{obj}:00 PM</div>
                            ])
                        }
                    </div>
                    <div className="list__time">
                        {
                            time.map((obj) => [
                                <div className={"items " + (Select == obj + ":30 AM" ? "active" : "")} onClick={() => setSelect(obj + ":30 AM")}>{obj}:30 AM</div>
                            ])
                        }
                        {
                            time.map((obj) => [
                                <div className={"items " + (Select == obj + ":30 PM" ? "active" : "")} onClick={() => setSelect(obj + ":30 PM")}>{obj}:30 PM</div>
                            ])
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Time