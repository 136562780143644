import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'

const People = () => {
    const [Select, setSelect] = useState("")
    const [Size, setSize] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"])
    // useEffect(() => {
    //     props.setMakeRequest(Select, "selectSize")
    // }, [Select])
    return (
        <Fragment>
            <div className="text-center general size">
                <h4 className='bold font__size--18'>Party Size?</h4>
                <p className='normal font__size--16 color__gray-2 mb-4'>Select the amount of people in party</p>

                <div className="mb-4 list__size">
                    {
                        Size.map((obj) => [
                            <div className={"items " + (Select == obj ? "active" : "")} onClick={() => setSelect(obj)}>{obj}</div>
                        ])
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default People