import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react'
import DatePicker from "react-datepicker";
import { format } from 'date-fns'

import "react-datepicker/dist/react-datepicker.css";

const SecDate = () => {
  const [startDate, setStartDate] = useState("");
  const onChange = (dates) => {
    // const [start, end] = dates;
    setStartDate(dates);
  };

  // useEffect(() => {
  //   props.setMakeRequest(startDate, "selectDate")
  // }, [startDate])
  return (
    <Fragment>
      <div className="text-center general date">
        <h4 className='bold font__size--18'>What’s the Occasion?</h4>
        <p className='normal font__size--16 color__gray-2 mb-3'>Help us tailor your experience.</p>

        <div className="mb-4 dateFormat">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            minDate={new Date()}
            inline
          />
        </div>

      </div>
    </Fragment>
  )
}

export default SecDate