import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// loading
import LoadingScreanVideo from "./LoadingScreanVideo";

// Auth
import Start from "./Auth/Index";
import Request from "./Auth/Request";
import Code from "./Auth/Code";
import Thanks from "./Auth/Thanks";
import Login from "./Auth/Login";
import Create from "./Auth/FirstTime";
import Register from "./Auth/Register";

// homepaghe
import HomeRequest from "./homepage/HomeRequest";
import PrivateRoute from "../utils/privateRoute";

const Index = () => {
  return (
    <div>
      {/* <LoadingScreanVideo /> */}
      <Router HashRouter>
        {/* <Router forceRefresh> */}
        <Switch>
          <Route exact path="/">
            <Start />
          </Route>
          <Route exact path="/request">
            <Request />
          </Route>
          <Route exact path="/code/:phone">
            <Code />
          </Route>
          <Route exact path="/thanks">
            <Thanks />
          </Route>

          {/* <Route
            exact
            path="/login"
            render={() => <Login handleShowModal={this.handleShowModal} />}
          /> */}

          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/create">
            <Create />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/homepage">
            <HomeRequest />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default Index;
