import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'

import Upcoming from './Content/Upcoming'
import Location from './Content/Location'
import Occasion from './Content/Occasion'
import SelectDate from './Content/SelectDate'
import SelectTime from './Content/SelectTime'
import SelectSize from './Content/SelectSize'
import Cuisine from './Content/Cuisine'
import Review from './Content/Review'

const ModalRequestStep = (props) => {

    const selectActiveContent = () => {
        if (props.ActiveModal == 1) {
            return <Upcoming
                setActiveModal={(e) => props.setActiveModal(e)}
                dataState={props.dataState}
                setMakeRequest={(e, b) => props.setMakeRequest(e, b)}
                updateReqData={(k, d) => props.updateReqData(k, d)}
            />
        } else if (props.ActiveModal == 2) {
            return <Location
                setActiveModal={(e) => props.setActiveModal(e)}
                dataState={props.dataState}
                setMakeRequest={(e, b) => props.setMakeRequest(e, b)}
                updateReqData={(k, d) => props.updateReqData(k, d)}
            />
        } else if (props.ActiveModal == 3) {
            return <Occasion
                setActiveModal={(e) => props.setActiveModal(e)}
                dataState={props.dataState}
                setMakeRequest={(e, b) => props.setMakeRequest(e, b)}
                updateReqData={(k, d) => props.updateReqData(k, d)}
            />
        } else if (props.ActiveModal == 4) {
            return <SelectDate
                setActiveModal={(e) => props.setActiveModal(e)}
                dataState={props.dataState}
                setMakeRequest={(e, b) => props.setMakeRequest(e, b)}
                updateReqData={(k, d) => props.updateReqData(k, d)}
            />
        } else if (props.ActiveModal == 5) {
            return <SelectTime
                setActiveModal={(e) => props.setActiveModal(e)}
                dataState={props.dataState}
                setMakeRequest={(e, b) => props.setMakeRequest(e, b)}
                updateReqData={(k, d) => props.updateReqData(k, d)}
            />
        } else if (props.ActiveModal == 6) {
            return <SelectSize
                setActiveModal={(e) => props.setActiveModal(e)}
                dataState={props.dataState}
                setMakeRequest={(e, b) => props.setMakeRequest(e, b)}
                updateReqData={(k, d) => props.updateReqData(k, d)}
            />
        } else if (props.ActiveModal == 7) {
            return <Cuisine
                setActiveModal={(e) => props.setActiveModal(e)}
                dataState={props.dataState}
                setMakeRequest={(e, b) => props.setMakeRequest(e, b)}
                updateReqData={(k, d) => props.updateReqData(k, d)}
            />
        } else if (props.ActiveModal == 8) {
            return <Review
                onCLickSwitchModal={(e, d) => props.onCLickSwitchModal(e, d)}
                setActiveModal={(e) => props.setActiveModal(e)}
                dataState={props.dataState}
                setMakeRequest={(e, b) => props.setMakeRequest(e, b)}
                reqData={props.reqData}
                setNewReqId={(x) => props.setNewReqId(x)}
                sendMsgSentSocket={props.sendMsgSentSocket}
            />
        }
    }

    return (
        <Fragment>
            <div className={"wrapper__wrap-up " + (props.ModalRequestStep ? "active" : "")} onClick={() => props.onCLickModalModalRequestStep()}></div>
            <div className={"wrapper__up-request " + (props.ModalRequestStep ? "active" : "")}>
                <div className="head text-center pointer" onClick={() => props.onCLickModalModalRequestStep()}>
                    <div className="ornamen"></div>
                </div>
                <div className="body">
                    <div className="container">
                        {selectActiveContent()}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ModalRequestStep