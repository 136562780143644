import React from "react";
import { CloseIcon } from "../../Svg/Svg";

const InstallPWAModal = ({ show, onHide, onInstall }) => {
  return (
    <div
      className={"wrapper__modal-wrap modal fade " + (show ? "show" : "")}
      id="pwaInstallModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="pwaInstallModalLabel"
      aria-hidden="true"
    >
      <div className="bg__wrap" onClick={onHide}></div>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content logout position-relative">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <div className="pointer d-inline-block" onClick={onHide}>
              <CloseIcon />
            </div>
          </div>
          <div className="text-center">
            <div className="wrapper__logo-modal position-relative mx-auto mb-4">
              <img src="./../images/Frame 38141.png" alt="" />
            </div>

            <h4 className="bold font__size--18">
              Get the best experience with our PWA!
            </h4>

            <hr className="hr__line-modal my-4" />

            <div className="mb-4">
              <p className="normal font__size--12 color__gray-1">
                Enjoy faster load times, automatic updates, and a more engaging
                user experience by installing our Progressive Web App. Click the
                button below to add it to your device.
              </p>
            </div>

            <div className="d-flex align-items-center">
              <a
                onClick={onInstall}
                href="#!"
                className={
                  "pd-logout rounded-20 medium font__size--14 btn btn__tosca color__black-2 shadow w-100 "
                }
              >
                Install Now
              </a>
              <div
                onClick={onHide}
                className="pd-logout rounded-20 ml-3 medium font__size--14 btn btn__white opacity-3 color__white shadow w-100"
              >
                Maybe Later
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallPWAModal;
