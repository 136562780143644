import React from 'react'
import { Fragment } from 'react';
import GifPlayer from "react-gif-player"
import gitFile from './1f1f704f22d703e1fdc430fb2a8381bd.gif';

const Gif = () => {
    return (
        <Fragment>
            <img src="./../images/Group 33855.png" className="images__cover-1" alt="" />
            <div className="wrapper__blur-wrap"></div>
            <GifPlayer gif={gitFile} still={gitFile} autoplay={true} className="images__cover-2" />
            {/* <img src={gitFile} className="images__cover-2" alt="" /> */}
        </Fragment>
    )
}

export default Gif