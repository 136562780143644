import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import PhotoEdit from "../Other/PhotoEdit";
import { CloseIcon } from "../Svg/Svg";
import axiosClient from "../../utils/apiClient";
import { useEffect } from "react";
import { Imagebuilder } from "aws-sdk";

const ModalProfile = (props) => {
  const [Name, setName] = useState(null);
  const [NameEdit, setNameEdit] = useState(false);
  const [img, setImg] = useState(null);

  useEffect(() => {
    if (props.user?.full_name) {
      setName(props.user.full_name);
    }
  }, [props.user?.full_name]);

  const uploadFileInDb = async () => {
    const formData = new FormData();
    formData.append("image", img);
    try {
      const res = await axiosClient.post("/uploadFile", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      // console.log(res.data);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  };

  const updateUserInDb = async () => {
    let profile_photo = props.user?.photo;
    if (img) {
      profile_photo = await uploadFileInDb();
      console.log(profile_photo);
      axiosClient
        .post(`/update-user`, {
          id: props.user?.id,
          full_name: Name,
          profile_photo: profile_photo,
        })
        .then((response) => {
          console.log(response.data);
          props.onCLickModalProfile();
          props.resetUser({ full_name: Name, profile_photo: profile_photo });
        })
        .catch((err) => console.log(err));
    } else {
      axiosClient
        .post(`/update-user`, {
          id: props.user?.id,
          full_name: Name,
        })
        .then((response) => {
          console.log(response.data);
          props.onCLickModalProfile();
          props.resetUser({ full_name: Name, profile_photo: profile_photo });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div
      class={
        "wrapper__modal-wrap modal fade " + (props.modalProfile ? "show" : "")
      }
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="bg__wrap"
        onClick={() => props.onCLickModalProfile()}
      ></div>
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content logout position-relative">
          <div className="text-center mb-5">
            <div
              className="pointer d-inline-block"
              onClick={() => props.onCLickModalProfile()}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="text-center">
            <div className="mb-4">
              <PhotoEdit
                img={img}
                profile_photo={props.user?.photo}
                setImg={(x) => {
                  setImg(x);
                  setNameEdit(true);
                }}
              />
            </div>

            <div className="">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="text-left">
                  <label
                    htmlFor=""
                    className="normal font__size--12 color__gray-2"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    disabled={NameEdit ? false : true}
                    value={Name}
                    className="medium font__size--16 color__white w-100 wrapper__field-profile"
                  />
                </div>
                <img
                  src="./../images/edit.png"
                  className="pointer"
                  onClick={() => setNameEdit(!NameEdit)}
                  alt=""
                />
              </div>

              <p className="light font__size--10 color__gray-2 lh-1 text-left">
                The name you choose will be used for bookings and visible to our
                team members who serve your request.
              </p>
            </div>

            <hr className="hr__line-modal my-4" />

            <div className="d-flex align-items-center">
              {props.status == "fistLoad" ? (
                <Fragment>
                  <div
                    onClick={() =>
                      !NameEdit
                        ? ""
                        : props.onCLickSwitchModal("profileToReservations", "")
                    }
                    className={
                      "pd-logout rounded-20 medium font__size--14 btn btn__tosca color__black-2 shadow w-100 " +
                      (!NameEdit ? "opacity__5" : "")
                    }
                  >
                    Save
                  </div>
                  <div
                    onClick={() =>
                      props.onCLickSwitchModal("profileTowelcome", "back")
                    }
                    className="pd-logout rounded-20 ml-3 medium font__size--14 btn btn__white opacity-3 color__white shadow w-100"
                  >
                    Discard
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div
                    onClick={() => {
                      NameEdit && updateUserInDb();
                    }}
                    className={
                      "pd-logout rounded-20 medium font__size--14 btn btn__tosca color__black-2 shadow w-100 " +
                      (!NameEdit ? "opacity__5" : "")
                    }
                  >
                    Save
                  </div>
                  <div
                    onClick={() => props.onCLickModalProfile()}
                    className="pd-logout rounded-20 ml-3 medium font__size--14 btn btn__white opacity-3 color__white shadow w-100"
                  >
                    Discard
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalProfile;
