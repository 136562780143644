import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Gif from "../../component/Gif/Gif";
import { MailIcon } from "../../component/Svg/Svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axiosClient from "../../utils/apiClient";
import Code from "./Code";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      step: 1,
      phone: "1",
      number: "1",
    };
  }

  componentDidMount() {
    const style = document.documentElement.style;
    style.setProperty("--height", `${window.innerHeight}px`);
  }

  onChangeInput(e) {
    this.setState({
      number: e,
    });
  }

  onRequest = () => {
    const number = "+" + String(this.state.phone) + String(this.state.number);
    axiosClient
      .post("/add-beta-user", { phone_no: number })
      .then((response) => {
        // this.props.history.push(`/code/${number}`);
        this.setState({ step: 2 });
        toast.success("OTP sent successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((err) => {
        console.log(err);
        // this.props.history.push(`/code/${number}`);
        this.setState({ step: 2 });
      });
  };

  render() {
    return (
      <Fragment>
        {this.state.step === 1 ? (
          <div className="overflow-hidden bg__black wrapper__wrap-all-screan">
            <div className="h-100 position-relative d-flex">
              <Gif />

              <div className="position-relative z-5 w-100 wrapper__bg-first align-self-end">
                <div className="container w-100 py-4">
                  <div className="text-center mb-5">
                    <img src="./../images/dsads.png" className="mb-2" alt="" />
                    <h2 className="bold font__size--18 lh-1">
                      Request an Invite
                    </h2>
                    <p className="normal font__size--14 mb-3">
                      Enter your number below and we'll send you an invite as
                      soon as possible.
                    </p>

                    <div className="wrapper__form-input text-left">
                      <div className="number">
                        <div className="d-inline-block">
                          <div className="wrap">
                            <PhoneInput
                              className="color__black"
                              country={"us"}
                              enableSearch={true}
                              value={this.state.phone}
                              onChange={(phone) => this.setState({ phone })}
                            />{" "}
                            <div className="ml-2">+{this.state.phone}</div>
                          </div>
                        </div>
                        <input
                          type="number"
                          onChange={(e) => this.onChangeInput(e.target.value)}
                          className="ml-2 medium color__white font__size--16"
                          placeholder="Phone number"
                        />
                      </div>
                    </div>
                    {/* {
                            this.state.status ? <p className="mb-0 color__red normal font__size--14">Number already exist in our system!</p> : ""
                    } */}
                  </div>

                  <button
                    onClick={this.onRequest}
                    className="bold font__size--16 btn btn__tosca shadow color__black-2 w-100 rounded__20"
                  >
                    Request code
                  </button>

                  <div className="text-center mt-4">
                    <NavLink
                      to="/"
                      className="mb-0 normal font__size--16 color__gray-2 decoration"
                    >
                      Back to Home
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Code
            phone={"+" + String(this.state.phone) + String(this.state.number)}
            onRequest={this.onRequest}
          />
        )}
        <ToastContainer />
      </Fragment>
    );
  }
}

export default withRouter(Request);
