import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { createContext } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {

    const [user, setUser] = useState({});
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        //get data from cookie
        refreshUser();
    }, [])

    const refreshUser = () => {
        const data = localStorage.getItem('user');
        if (data) {
            setUser(JSON.parse(data));
        }
    }

    return <UserContext.Provider value={{ user, setUser, socket, setSocket }}>{children}</UserContext.Provider>
}

const useUserContext = () => {
    return useContext(UserContext);
}
export { UserProvider, useUserContext, UserContext };

