import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { BackIcon } from '../../../../Svg/Svg';

const SelectTime = (props) => {
    const [Select, setSelect] = useState("5:00 PM");
    const ref1 = useRef();
    const ref2 = useRef();

    const [time, settime] = useState([
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
    ]);

    useEffect(() => {
        props.setMakeRequest(Select, "selectTime");
    }, [Select]);

    useEffect(() => {
        const div = ref1.current;
        const scrollWidth = div.scrollWidth - div.clientWidth;
        // console.log(scrollWidth);
        ref1.current.scrollLeft = (70 * scrollWidth) / 100;
        ref2.current.scrollLeft = (70 * scrollWidth) / 100;
    }, []);

    return (
        <Fragment>
            <div className="text-center general time">
                <h4 className="bold font__size--18">What will be the Time?</h4>
                <p className="normal font__size--16 color__gray-2 mb-5">
                    Select a time that suits your needs.
                </p>

                <div className="mb-5">
                    <div className="list__time mb-4" ref={ref1}>
                        {time.map((obj) => [
                            <div
                                className={
                                    "items " + (Select == obj + ":00 AM" ? "active" : "")
                                }
                                onClick={() => setSelect(obj + ":00 AM")}
                            >
                                {obj}:00 AM
                            </div>,
                        ])}
                        {time.map((obj) => [
                            <div
                                className={
                                    "items " + (Select == obj + ":00 PM" ? "active" : "")
                                }
                                onClick={() => setSelect(obj + ":00 PM")}
                            >
                                {obj}:00 PM
                            </div>,
                        ])}
                    </div>
                    <div className="list__time" ref={ref2}>
                        {time.map((obj) => [
                            <div
                                className={
                                    "items " + (Select == obj + ":30 AM" ? "active" : "")
                                }
                                onClick={() => setSelect(obj + ":30 AM")}
                            >
                                {obj}:30 AM
                            </div>,
                        ])}
                        {time.map((obj) => [
                            <div
                                className={
                                    "items " + (Select == obj + ":30 PM" ? "active" : "")
                                }
                                onClick={() => setSelect(obj + ":30 PM")}
                            >
                                {obj}:30 PM
                            </div>,
                        ])}
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <div
                        onClick={() =>
                            props.setActiveModal(4)
                        }
                        className="arrow flex-shrink-0 pointer"
                    >
                        <BackIcon />
                    </div>
                    <div
                        onClick={() => (
                            props.setActiveModal(6),
                            props.updateReqData("time", Select)
                        )}
                        className={
                            "btn btn__tosca btn__select w-100 normal font__size--14 " +
                            (Select ? "active" : "")
                        }
                    >
                        Next: <b>Guests</b>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SelectTime;
