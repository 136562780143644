import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";

const Upcoming = (props) => {
    const [Select, setSelect] = useState("");
    useEffect(() => {
        props.setMakeRequest(Select, "typeChoose");
    }, [Select]);

    return (
        <Fragment>
            <div className="text-center upcoming">
                <h4 className="bold font__size--18">Want a reservation?</h4>
                <p className="normal font__size--16 color__gray-2 mb-3">
                    Lets start!
                </p>

                <div className="select row medium font__size--10 color__gray-2 mb-3">
                    <div className="col-6">
                        <div
                            className={
                                "items lh__5 pointer " + (Select == "1" ? "active" : "")
                            }
                            onClick={() => setSelect("1")}
                        >
                            PLEASE RECOMMEND ME A PLACE
                        </div>
                    </div>
                    <div className="col-6">
                        <div
                            className={
                                "items lh__5 pointer " + (Select == "2" ? "active" : "")
                            }
                            onClick={() => setSelect("2")}
                        >
                            I HAVE A SPECIFIC PLACE IN MIND
                        </div>
                    </div>
                </div>

                <div
                    onClick={() =>
                        Select
                            ? (props.setActiveModal(2),
                                props.updateReqData(
                                    "location option",
                                    Select === 1 ? "RECOMMENDED" : "SPECIFIC"
                                ))
                            : ""
                    }
                    className={
                        "btn btn__tosca btn__select w-100 normal font__size--14 " +
                        (Select ? "active" : "")
                    }
                >
                    Next: <b>Location</b>
                </div>
            </div>
        </Fragment>
    );
};

export default Upcoming;
