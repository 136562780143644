import React from "react";
import { CheckDoneIcon, InfoCircleIcon } from "../../Svg/Svg";
import moment from "moment";

const UserTextChat = (props) => {
  return (
    <div className="wrapper__user-text-chat mb-4">
      <div className="wrap">
        {props.edited ? (
          <div className="edit mb-2">
            <div
              className="d-flex align-items-center"
              onClick={() => props.setOnClickUpdatedChat()}
            >
              <InfoCircleIcon width={16} height={16} color={"#E93527"} />{" "}
              <span className="ml-2 normal font__size--10 color__gray-1">
                <span className="medium color__red decoration">Tap Link</span>{" "}
                to edit them and request changes.
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className="text__desc color__gray-3 medium font__size--12 mb-3"
          dangerouslySetInnerHTML={{ __html: props.data.message }}
        >
          {/* Hi, I'm looking for a restaurant recommendation for <span className='color__red decoration'>5 people</span> at <span className='color__red decoration'>1:30 pm</span> on <span className='color__red decoration'>13 Mar 2023</span> in <span className='color__red decoration'>San Diego</span>. I’m looking for <span className='color__red decoration'>Italian cuisine</span> and would prefer a <span className='color__red decoration'>Business</span> atmosphere. */}
        </div>
        <div className="text-right">
          <div className="d-flex align-items-center justify-content-end">
            {props.updated ? (
              <span
                className="mr-2 semi-bold font__size--10 color__red text-up
                            "
              >
                UPDATED
              </span>
            ) : (
              ""
            )}
            <span className="normal font__size--10 color__gray-1 mr-2">
              {moment(props.data.createdAt).format("DD-MM-YY h:mm a")}
            </span>
            <CheckDoneIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTextChat;
