import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { BreadIcon, BriyaniIcon, DumplingIcon, PizzaIcon, TacosIcon } from '../../../Svg/Svg'

const Cuisine = () => {
    const [Select, setSelect] = useState("")
    // useEffect(() => {
    //     props.setMakeRequest(Select, "cuisine")
    // }, [Select])
    return (
        <Fragment>
            <div className="text-center general cuisine">
                <h4 className='bold font__size--18'>What Cuisine Craves Your Attention?</h4>
                <p className='normal font__size--16 color__gray-2 mb-3'>Choose your preferred cuisine type.</p>

                <div className="mb-4 list__wrap updated">
                    <div className={"items pointer " + (Select == "Italian" ? "active" : "")} onClick={() => setSelect("Italian")}>
                        <PizzaIcon />
                        <div className="line"></div>
                        <div className='text-left'>
                            <div className='normal font__size--16'>Italian Food</div>
                            <p className='mb-0 light font__size--12 color__gray-2'>Pasta, pizza, risotto, etc</p>
                        </div>
                    </div>
                    <div className={"items pointer " + (Select == "Mexican" ? "active" : "")} onClick={() => setSelect("Mexican")}>
                        <TacosIcon />
                        <div className="line"></div>
                        <div className='text-left'>
                            <div className='normal font__size--16'>Mexican Food</div>
                            <p className='mb-0 light font__size--12 color__gray-2'>Tacos, enchiladas, guacamole, etc</p>
                        </div>
                    </div>
                    <div className={"items pointer " + (Select == "Chinese" ? "active" : "")} onClick={() => setSelect("Chinese")}>
                        <DumplingIcon />
                        <div className="line"></div>
                        <div className='text-left'>
                            <div className='normal font__size--16'>Chinese Food</div>
                            <p className='mb-0 light font__size--12 color__gray-2'>Dumplings, stir-fries, noodle soups,etc</p>
                        </div>
                    </div>
                    <div className={"items pointer " + (Select == "Indian" ? "active" : "")} onClick={() => setSelect("Indian")}>
                        <BriyaniIcon />
                        <div className="line"></div>
                        <div className='text-left'>
                            <div className='normal font__size--16'>Indian Food</div>
                            <p className='mb-0 light font__size--12 color__gray-2'>Curry, biryani, naan bread, etc</p>
                        </div>
                    </div>
                    <div className={"items pointer " + (Select == "French" ? "active" : "")} onClick={() => setSelect("French")}>
                        <BreadIcon />
                        <div className="line"></div>
                        <div className='text-left'>
                            <div className='normal font__size--16'>French Food</div>
                            <p className='mb-0 light font__size--12 color__gray-2'>Coq au vin, bouillabaisse, quiche, etc</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Cuisine