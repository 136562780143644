import React, { useState } from "react";
import { Fragment } from "react";
import SecModalRequestStep from './ModalRequestStep'
import { useEffect } from "react";
import RequestUpdate from "../Updated/RequestUpdate";
import SecChatListDetail from "../../../Chat/ChatListDetail";

const RequestStep = (props) => {
  const [reqData, setReqData] = useState({});
  const [newReqId, setNewReqId] = useState(null);

  console.log("reqData>>", reqData);
  const updateReqData = (key, value) => {
    setReqData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const [ActiveModal, setActiveModal] = useState(1)

  const [ModalRequestStep, setModalRequestStep] = useState(false);
  const onCLickModalModalRequestStep = () => {
    props.setOnClickRequest(false)
    setActiveModal(1)
    if (!ModalRequestStep) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalRequestStep(!ModalRequestStep);
  };


  const onCLickSwitchModal = async (e, d) => {
    if (e == "ReviewToChatListDetail") {
      onCLickModalModalRequestStep();
      onCLickModalChatListDetail();
    }

  }

  useEffect(() => {
    if (props.MakeRequest) {
      onCLickModalModalRequestStep()
    }
  }, [props.statusClick])



  const [ChatListDetail, setChatListDetail] = useState(false);
  const onCLickModalChatListDetail = () => {
    if (!ChatListDetail) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setChatListDetail(!ChatListDetail);
  };

  const [RequetUpdate, setRequetUpdate] = useState(false);
  const onCLickModalRequetUpdate = () => {
    setOnClickUpdatedChat(false);
    if (!RequetUpdate) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setRequetUpdate(!RequetUpdate);
  };

  const [RequetUpdateOnChat, setRequetUpdateOnChat] = useState(false);

  const setOnClickUpdatedChat = (e) => {
    setRequetUpdateOnChat(e == false ? e : !RequetUpdateOnChat)
  }

  useEffect(() => {
    if (RequetUpdateOnChat) {
      onCLickModalRequetUpdate()
    }
  }, [RequetUpdateOnChat])


  return (
    <Fragment>

      <SecModalRequestStep
        setActiveModal={(e) => setActiveModal(e)}
        ActiveModal={ActiveModal}
        dataState={props.dataState}
        setMakeRequest={(e, b) => props.setMakeRequest(e, b)}
        ModalRequestStep={ModalRequestStep}
        onCLickModalModalRequestStep={() => onCLickModalModalRequestStep()}
        onCLickSwitchModal={(e, d) => onCLickSwitchModal(e, d)}
        updateReqData={(k, d) => updateReqData(k, d)}
        reqData={reqData}
        setNewReqId={(x) => setNewReqId(x)}
        sendMsgSentSocket={props.sendMsgSentSocket}
      />

      <RequestUpdate
        RequetUpdate={RequetUpdate}
        onCLickModalRequetUpdate={() => onCLickModalRequetUpdate()}
      />
      <SecChatListDetail
        reqArr={props.dataState.reqArr}
        setOnClickUpdatedChat={() => setOnClickUpdatedChat()}
        userId={props.userId}
        reqId={newReqId}
        ChatListDetail={ChatListDetail}
        occasion={reqData?.occasion}
        sendMsgSentSocket={props.sendMsgSentSocket}
        onCLickModalChatListDetail={() => {
          onCLickModalChatListDetail();
          props.resetPrevSelection();
        }}
      />
    </Fragment>
  );
};

export default RequestStep;
