import React, { useRef } from "react";
import { Fragment } from "react";
import SecChatRequest from "./ChatRequest";
import SecChatListDetail from "./ChatListDetail";
import { useState } from "react";
import { useEffect } from "react";
import RequestUpdate from "../Modal/Request/Updated/RequestUpdate";
import axiosClient from "../../utils/apiClient";
// import { io } from "socket.io-client";
import { useUserContext } from "../../utils/userContext";

const ChatStep = (props) => {
  const [chatRequest, setChatRequest] = useState(false);
  const [reqArr, setReqArr] = useState([]);
  const socket = useRef();
  const { socket: socketCon } = useUserContext();

  const onCLickModalChatRequest = () => {
    props.setOnClickChat(false);
    if (!chatRequest) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setChatRequest(!chatRequest);
  };

  // console.log("chatRequest>>", chatRequest);
  const [ChatListDetail, setChatListDetail] = useState(false);
  const onCLickModalChatListDetail = () => {
    if (!ChatListDetail) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setChatListDetail(!ChatListDetail);
  };

  const [RequetUpdate, setRequetUpdate] = useState(false);
  const onCLickModalRequetUpdate = () => {
    setOnClickUpdatedChat(false);
    if (!RequetUpdate) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setRequetUpdate(!RequetUpdate);
  };

  useEffect(() => {
    if (socketCon) {
      setSockets();
    }
  }, [socketCon]);

  useEffect(() => {
    if (props.dataState.clickChat) {
      //   console.log(props.userId);
      fetchBookingRequests(props.userId);
      onCLickModalChatRequest();
    }
  }, [props.dataState.clickChat]);

  const setSockets = () => {
    socket.current = socketCon;
    console.log("setSockets", socketCon);
    // socket.current.on("init", function (data) {
    //   console.log("Socket Connection initialized");
    // });
    //when new user added
    socket.current.on("incoming-msg", function (data) {
      console.log("msg added", data);
      fetchBookingRequests(props.userId);
      // fetchUsers();
      // rearrangeUsers(data);
    });
  };

  const fetchBookingRequests = (userId) => {
    axiosClient
      .get(`/allreqs/${userId}/`)
      .then((response) => {
        setReqArr(response.data);
        // onCLickModalChatRequest();
      })
      .catch((err) => console.log(err));
  };

  const [RequetUpdateOnChat, setRequetUpdateOnChat] = useState(false);
  const setOnClickUpdatedChat = (e) => {
    setRequetUpdateOnChat(e == false ? e : !RequetUpdateOnChat);
  };

  useEffect(() => {
    if (RequetUpdateOnChat) {
      onCLickModalRequetUpdate();
    }
  }, [RequetUpdateOnChat]);

  // console.log(chatRequest);

  return (
    <Fragment>
      <RequestUpdate
        RequetUpdate={RequetUpdate}
        onCLickModalRequetUpdate={() => onCLickModalRequetUpdate()}
      />
      {ChatListDetail && (
        <SecChatListDetail
          setOnClickUpdatedChat={() => setOnClickUpdatedChat()}
          userId={props.userId}
          reqId={chatRequest}
          selectedRequest={
            chatRequest ? reqArr.find((x) => x.id === chatRequest) : null
          }
          reqArr={props.dataState.reqArr}
          ChatListDetail={ChatListDetail}
          resetSelectedRequestUnseenCount={() =>
            setReqArr((prev) =>
              prev.map((x) => {
                if (x.id === chatRequest) return { ...x, unseen: 0 };
                else return x;
              })
            )
          }
          sendMsgSentSocket={(data) => socket.current.emit("msg-sent", data)}
          onCLickModalChatListDetail={() => onCLickModalChatListDetail()}
        />
      )}
      <SecChatRequest
        chatRequest={chatRequest}
        setChatRequest={setChatRequest}
        chatReqArr={reqArr}
        onCLickModalChatRequest={() => onCLickModalChatRequest()}
        onCLickModalChatListDetail={() => onCLickModalChatListDetail()}
      />
    </Fragment>
  );
};

export default ChatStep;
