import React, { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import People from './People'
import Time from './Time'
import SecDate from './SecDate'
import Location from './Location'
import Cuisine from './Cuisine'
import Occasion from './Occasion'

const RequestUpdate = (props) => {
    const [Category, setCategory] = useState([
        "People", "Time", "Date", "Location", 'Cuisine', 'Occasion'
    ])
    const [SelectCategory, setSelectCategory] = useState("People")
    const [Select, setSelect] = useState("")

    return (
        <Fragment>
            <div onClick={() => props.onCLickModalRequetUpdate()} className={"wrapper__wrap-up updated " + (props.RequetUpdate ? "active" : "")}></div>
            <div className={"wrapper__up-request updated " + (props.RequetUpdate ? "active" : "")}>
                <div className="head text-center pointer" onClick={() => props.onCLickModalRequetUpdate()}>
                    <div className="ornamen"></div>
                </div>
                <div className="body">
                    <div className="container">
                        <div className="wrapper__category-slide mb-4">
                            {
                                Category.map((obj) => {
                                    return <div onClick={() => setSelectCategory(obj)} className={"items pointer " + (SelectCategory == obj ? "active" : "")}>
                                        {obj}
                                    </div>
                                })
                            }

                        </div>
                        {
                            SelectCategory == "People" ? <People /> : SelectCategory == "Time" ? <Time /> : SelectCategory == "Date" ? <SecDate /> : SelectCategory == "Location" ? <Location /> : SelectCategory == "Cuisine" ? <Cuisine /> : <Occasion />
                        }

                        <div onClick={() => props.onCLickModalRequetUpdate()} className={"btn btn__tosca btn__select w-100 normal font__size--14 active"}>Request Again</div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RequestUpdate