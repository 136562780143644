import React from 'react'
import { SearchIcon } from '../Svg/Svg'

const Search = () => {
  return (
    <div className="container">
      <div className="wrapper__search">
        <SearchIcon />
        <input type="text" placeholder='Search' className='form-control medium font__size--14 color__white ml-2 w-100' />
      </div>
    </div>
  )
}

export default Search