import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useUserContext } from "../../utils/userContext";
import { CloseIcon } from "../Svg/Svg";

const ModalLogout = (props) => {
  const history = useHistory();
  const { setUser } = useUserContext();

  return (
    <div
      class={
        "wrapper__modal-wrap modal fade " + (props.modalLogout ? "show" : "")
      }
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="bg__wrap"
        onClick={() => props.onCLickModalLogout()}
      ></div>
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content logout position-relative">
          <div className="text-center">
            <div
              className="pointer d-inline-block"
              onClick={() => props.onCLickModalLogout()}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="text-center">
            <img
              src="./../images/material-symbols_logout-rounded.png"
              className=""
              alt=""
            />

            <h4 className="bold font__size--18 mb-0">Are you sure?</h4>
            <hr className="hr__home-line my-3" />
            <p className="normal font__size--12 color__gray-2 mb-5">
              Logging out will end your current session and you will need to log
              back in to access your account.
            </p>

            <div className="d-flex align-items-center">
              <div
                onClick={() => {
                  localStorage.removeItem("user");
                  setUser({});
                  history.push("/login");
                }}
                className="pd-logout rounded-20 medium font__size--14 btn btn__tosca color__black-2 shadow w-100"
              >
                Yes, LogOut
              </div>
              <div
                onClick={() => props.onCLickModalLogout()}
                className="pd-logout rounded-20 ml-3 medium font__size--14 btn btn__white opacity-3 color__white shadow w-100"
              >
                No, Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLogout;
