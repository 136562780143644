import React, { Fragment } from "react";
import { useState } from "react";
import { useUserContext } from "../../utils/userContext";
import ModalLogout from "../Modal/ModalLogout";
import ModalProfile from "../Modal/ModalProfile";

const TopProfile = () => {
  const { user, setUser } = useUserContext();
  console.log("TopProfile>>", user);
  const [modalLogout, setModalLogout] = useState(false);

  const onCLickModalLogout = () => {
    if (!modalLogout) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalLogout(!modalLogout);
  };

  const [modalProfile, setModalProfile] = useState(false);
  const onCLickModalProfile = () => {
    if (!modalProfile) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalProfile(!modalProfile);
  };
  return (
    <Fragment>
      <ModalProfile
        modalProfile={modalProfile}
        user={user}
        onCLickModalProfile={() => onCLickModalProfile()}
        resetUser={(data) => {
          setUser({
            ...user,
            full_name: data.full_name,
            photo: data.profile_photo,
          });
          localStorage.removeItem("user");
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...user,
              full_name: data.full_name,
              photo: data.profile_photo,
            })
          );
        }}
      />
      <ModalLogout
        modalLogout={modalLogout}
        onCLickModalLogout={() => onCLickModalLogout()}
      />
      <div className="wrapper__top-nav-profile position-relative z-2">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div
              className="profile pointer"
              onClick={() => onCLickModalProfile()}
            >
              <img
                src={
                  user.photo
                    ? `${process.env.REACT_APP_BACKEND_URL}/api/getFile/${user.photo}`
                    : `https://ui-avatars.com/api/?name=${user?.full_name}`
                }
                alt=""
                style={{
                  borderRadius: "50%",
                  height: "2.6rem",
                  width: "2.6rem",
                }}
              />
              <span className="medium font__size--16 ml-3 color__white">
                Hi, {user?.full_name}!
              </span>
            </div>

            <div
              className="medium font__size--12 color__gray-1 pointer"
              onClick={() => onCLickModalLogout()}
            >
              LogOut
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TopProfile;
