import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Gif from "../../component/Gif/Gif";
import { KeyIcon } from "../../component/Svg/Svg";

export default class FirstTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            status: false,
        };
    }

    componentDidMount() {
        const style = document.documentElement.style;
        style.setProperty("--height", `${window.innerHeight}px`);
    }

    render() {
        return (
            <Fragment>
                <div className="overflow-hidden bg__black wrapper__wrap-all-screan">
                    <div className="h-100 position-relative d-flex">

                        <Gif />

                        <div className="position-relative z-5 w-100 wrapper__bg-first align-self-end">
                            <div className="container w-100 py-4">
                                <div className="text-center mb-5">
                                    <img src="./../images/dsads.png" className="mb-2" alt="" />
                                    <h2 className="bold font__size--18 lh-1">Create Your Password</h2>
                                    <p className="normal font__size--14 mb-3">To set up your account and start exploring <b>CENTURION</b>, please create a password below.</p>

                                    <div className="wrapper__field-input mb-3">
                                        <KeyIcon />
                                        <input type="password" className="form-control medium font__size--14 color__white" placeholder="Create Password" />
                                    </div>
                                    <div className="wrapper__field-input mb-3">
                                        <KeyIcon />
                                        <input type="password" className="form-control medium font__size--14 color__white" placeholder="Create Password" />
                                    </div>

                                    <p className="mb-0 color__red normal font__size--14">Password do not match! Try again.</p>

                                </div>
                                <NavLink to="/create" className="bold font__size--16 btn btn__tosca shadow color__black-2 w-100 rounded__20">Setup Profile</NavLink>



                                <div className="text-center mt-4">
                                    <NavLink to="/" className="mb-0 normal font__size--16 color__gray-2 decoration">Back to Home</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        );
    }
}
