import React from "react";

const ChatList = (props) => {
  return (
    <div
      className={
        "wrapper__chatList pointer color__white " +
        (props.data.notif > 0 ? "active" : "")
      }
    >
      <div className="container">
        <div className="detail">
          <div className="img__user flex-shrink-0">
            <img src={props.data.img} alt="" />
            {props.data.notif > 0 ? (
              <div className="count font__size--10">{props.data.notif}</div>
            ) : (
              ""
            )}
          </div>
          <div className="ml-3">
            <h5 className="semi-bold font__size--14 mb-1">
              {props.data.title}
            </h5>
            <p
              className="normal font__size--12 clamp__1 lh__2 mb-2"
              dangerouslySetInnerHTML={{ __html: props.data.desc }}
            ></p>
            <p className="normal font__size--10 clamp__1 lh__2 color__gray-2 mb-0">
              {props.data.date}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatList;
