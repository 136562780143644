import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Gif from "../../component/Gif/Gif";
import { KeyIcon } from "../../component/Svg/Svg";
import axiosClient from "../../utils/apiClient";
import { UserContext } from "../../utils/userContext";
import { withRouter } from "react-router-dom";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
      status: false,
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    const style = document.documentElement.style;
    style.setProperty("--height", `${window.innerHeight}px`);
  }

  validateUser = () => {
    console.log(this.props);
    axiosClient
      .post("/validate-user", {
        contact_no: this.props.userData?.phone_no,
        pass: this.state.password,
      })
      .then((response) => {
        if (response.data.err_msg) {
          this.setState({
            error: "Invalid Password!",
          });
        } else {
          const userobj = response.data;
          // console.log(userobj, response.data);
          //   const userobj = { full_name: "Sanu Shaw" };
          localStorage.setItem("user", JSON.stringify(userobj));
          this.context.setUser(userobj);
          this.props.history.push("/homepage");
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <Fragment>
        <div className="overflow-hidden bg__black wrapper__wrap-all-screan">
          <div className="h-100 position-relative d-flex">
            <Gif />

            <div className="position-relative z-5 w-100 wrapper__bg-first align-self-end">
              <div className="container w-100 py-4">
                <div className="text-center mb-5">
                  <img src="./../images/dsads.png" className="mb-2" alt="" />
                  <h2 className="bold font__size--18 lh-1">
                    {!this.props.userData?.setPassFlag ? "Enter" : "Create"}{" "}
                    Your Password
                  </h2>
                  <p className="normal font__size--14 mb-3">
                    {!this.props.userData?.setPassFlag ? "Enter" : "Create"}{" "}
                    your password to log in and access our exclusive dining &
                    lifestyle experiences.
                  </p>

                  <div className="wrapper__field-input">
                    <KeyIcon />
                    <input
                      type="password"
                      className="form-control medium font__size--14 color__white"
                      placeholder={`${
                        !this.props.userData?.setPassFlag ? "Enter" : "Create"
                      } Password`}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </div>
                  {this.state.error && (
                    <p className="d-flex justify-content-center mb-0 color__red normal font__size--14">
                      {this.state.error}
                    </p>
                  )}
                </div>
                <button
                  //   to="/homepage"
                  disabled={!this.state.password}
                  onClick={this.validateUser}
                  className="bold font__size--16 btn btn__tosca shadow color__black-2 w-100 rounded__20"
                >
                  Login
                </button>

                <div className="text-center mt-4">
                  <NavLink
                    to="/"
                    className="mb-0 normal font__size--16 color__gray-2 decoration"
                  >
                    Back to Home
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Register);
