import React from 'react'
import { NavLink } from 'react-router-dom'
import { InfoCircleIcon, Message } from '../Svg/Svg'

const MakeRequest = (props) => {
  return (
    <div className="wrapper__make-request">
      <div className="container">
        {
          props.AlertDetail ?
            <div className='d-flex align-items-center mb-3'>
              <InfoCircleIcon />
              <span className='ml-2 normal font__size--14'><span className='medium pointer decoration'>Tap here</span> to learn about membership</span>
            </div>
            : ""
        }
        <div className="d-flex align-items-center">
          {
            props.AlertDetail ? <div className='medium font__size--14 btn btn__tosca color__black-2 rounded-20 shadow w-100 pointer'>Begin Membership</div> : <div onClick={() => props.setOnClickRequest()} className='medium font__size--14 btn btn__tosca color__black-2 rounded-20 shadow w-100'>Make a Request</div>
          }

          <div className="message flex-shrink-0 position-relative ml-3 pointer" onClick={() => props.setOnClickChat()}>
            {
              props.messageNotification ? <div className="count medium font__size--6 color__black">{props.MessageCount}</div> : ""
            }

            <Message />
          </div>
        </div>
      </div>
    </div >
  )
}

export default MakeRequest