import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { format } from "date-fns";
import {
    BusinessIcon,
    CalendarIcon,
    CasualIcon,
    LocationIcon,
    RomanticIcon,
    SportIcon,
    TwoUserIcon,
    BreadIcon,
    BriyaniIcon,
    DumplingIcon,
    PizzaIcon,
    TacosIcon,
    StarIcon,
} from '../../../../Svg/Svg';
import axiosClient from "../../../../../utils/apiClient";
import { useUserContext } from "../../../../../utils/userContext";
import axios from "axios";

const Review = (props) => {
    const [Select, setSelect] = useState("");
    const [imgUrl, setImgUrl] = useState(null);
    const { user } = useUserContext();

    console.log(props);

    const iconForOccasion = (e) => {
        if (e == "Business") {
            return <BusinessIcon />;
        } else if (e == "Casual") {
            return <CasualIcon />;
        } else if (e == "Romantic") {
            return <RomanticIcon />;
        } else {
            return <SportIcon />;
        }
    };
    const iconForCuisine = (e) => {
        if (e == "Italian") {
            return <PizzaIcon />;
        } else if (e == "Mexican") {
            return <TacosIcon />;
        } else if (e == "Chinese") {
            return <DumplingIcon />;
        } else if (e == "Indian") {
            return <BriyaniIcon />;
        } else {
            return <BreadIcon />;
        }
    };

    const saveReqToDb = () => {
        const obj = {
            user_id: user.id,
            message: `I am looking for a restaurant recommendation for <span class="color__red decoration">${props.reqData.noOfPeople
                }</span> people at <span class="color__red decoration">${props.reqData.time
                }</span> on <span class="color__red decoration">${props.reqData.date
                }</span> in location <span class="color__red decoration">${props.reqData?.place
                    ? props.reqData.place?.label
                    : props.reqData.location?.name
                }</span>. I am looking for <span class="color__red decoration">${props.reqData["food-type"]
                }</span> and would prefer a <span class="color__red decoration">${props.reqData["occasion"]
                }</span> atmosphere`,
            obj: JSON.stringify(props.reqData).replaceAll('"', "'"),
        };
        console.log(obj);
        axiosClient
            .post(`/addReq`, obj)
            .then((response) => {
                console.log(response);
                props.onCLickSwitchModal("ReviewToChatListDetail");
                props.setNewReqId(response.data?.split("||")[1]);
                props.sendMsgSentSocket({
                    request_id: response.data?.split("||")[1],
                    sender_id: user.id,
                    user_id: user.id,
                    receiver_id: "1e1cde20-d813-11ed-b34f-1d28bd000000",
                });
                // props.resetPrevSelection();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getImgFromApi = async (placeRef) => {
        if (placeRef) {
            const url = `${process.env.REACT_APP_BACKEND_URL}/getImg/${placeRef}`;
            const res = await axios.get(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            });
            console.log(res.data);
            setImgUrl(res.data);
        }
    };

    useEffect(() => {
        if (props.dataState.place)
            getImgFromApi(props.dataState.place.value?.place_id);
    }, [props.dataState.place]);

    return (
        <Fragment>
            <div className="text-center general review">
                <h4 className="bold font__size--18">Lets review everything!</h4>
                <p className="normal font__size--16 color__gray-2 mb-4">
                    Edit or Request your preferences.
                </p>
                {props.dataState.typeChoose == 2 ? (
                    <div className="wrapper__card-review text-left">
                        <div className="head">
                            <div className="content">
                                <div className="desc">
                                    <div className="cover flex-shrink-0">
                                        <img src={imgUrl} alt="" />
                                    </div>
                                    <div className="ml-3">
                                        <h5 className="bold font__size--12">
                                            {props.dataState.place.label}
                                        </h5>
                                        {props.dataState.place.value &&
                                            props.dataState.place.value?.types
                                                .slice(0, 2)
                                                .map((x) => (
                                                    <div className="my-2 tag normal font__size--10 color__tosca mr-2">
                                                        {x}
                                                    </div>
                                                ))}
                                        <p className="mb-0 normal lh-1 font__size--10 lh__4 color__gray-2">
                                            {
                                                props.dataState.place.value?.structured_formatting
                                                    ?.secondary_text
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="star flex-shrink-0">
                                    <StarIcon color={"#00E6F6"} />
                                    <StarIcon color={"#00E6F6"} />
                                    <StarIcon color={"#00E6F6"} />
                                    <StarIcon color={"#00E6F6"} />
                                    <StarIcon color={"#00E6F6"} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <div className="mb-4 reveiw__list">
                    <div className="d-flex align-items-center flex-wrap">
                        <div className="items tag">
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <TwoUserIcon width={20} height={20} color={"#00E6F6"} />{" "}
                                    <span className="ml-2 medium font__size--14 color__gray-2">
                                        {props.dataState.selectSize
                                            ? props.dataState.selectSize
                                            : 0}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center ml-3">
                                    <CalendarIcon color={"#00E6F6"} />{" "}
                                    <span className="ml-2 medium font__size--14 color__gray-2">
                                        {props.dataState.selectTime
                                            ? props.dataState.selectTime
                                            : ""}
                                        ,{" "}
                                        {props.dataState.selectDate
                                            ? format(props.dataState.selectDate, "do MMM yyyy ")
                                            : format(new Date(), "do MMM yyyy ")}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="items tag">
                            <div className="d-flex align-items-center">
                                <LocationIcon color={"#00E6F6"} />{" "}
                                <span className="ml-2 medium font__size--14 color__gray-2">
                                    {props.dataState.location
                                        ? props.dataState.location
                                        : "Null"}
                                </span>
                            </div>
                        </div>
                        <div className="items tag">
                            <div className="d-flex align-items-center">
                                {iconForOccasion(props.dataState.occasion)}{" "}
                                <span className="ml-2 medium font__size--14 color__gray-2">
                                    {props.dataState.occasion
                                        ? props.dataState.occasion
                                        : props.dataState.occasion}
                                </span>
                            </div>
                        </div>
                        {props.dataState.typeChoose == 1 ? (
                            <div className="items tag">
                                <div className="d-flex align-items-center">
                                    {iconForCuisine(props.dataState.cuisine)}{" "}
                                    <span className="ml-2 medium font__size--14 color__gray-2">
                                        {props.dataState.cuisine
                                            ? props.dataState.cuisine
                                            : ""}{" "}
                                        Food
                                    </span>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                {/* <textarea
                placeholder="Any specific request that we should know?"
                className="medium font__size--14 color__white mb-4"
                name=""
                id=""
                cols="30"
                rows="3"
              ></textarea> */}

                <div
                    onClick={() => saveReqToDb()}
                    className="btn btn__tosca btn__select w-100 medium font__size--14 active"
                >
                    Request This
                </div>
            </div>
        </Fragment>
    );
};

export default Review;
