import React, { useState } from "react";
import { Fragment } from "react";
import { BackIcon, CloseXIcon, EditIcon, SendIcon } from "../Svg/Svg";
import AdminTextChat from "./Message/AdminTextChat";
import UserTextChat from "./Message/UserTextChat";
import axiosClient from "../../utils/apiClient";
import { useEffect } from "react";
import { useRef } from "react";

const ChatListDetail = (props) => {
  const [allChats, setAllChats] = useState([]);
  const [msg, setMsg] = useState("");
  const messagesEndRef = useRef();

  // console.log("props.reqArr", props.reqArr);
  useEffect(() => {
    // console.log(props.reqId, "==", String(props.reqId).length > 10);
    if (
      props.reqId &&
      String(props.reqId).length > 10 &&
      props.userId &&
      props.userId !== undefined
    ) {
      fetchAllChats();
    }
  }, [props.reqId, props.selectedRequest?.unseen, props.reqArr]);

  useEffect(() => {
    if (props.selectedRequest && +props.selectedRequest?.unseen > 0) {
      axiosClient
        .get(`/readall/${props.reqId}`)
        .then((response) => {
          console.log(response.data);
          // props.resetUnseenCount(props.selectedUser?.phone);
          props.resetSelectedRequestUnseenCount();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.selectedRequest]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allChats]);

  const fetchAllChats = () => {
    axiosClient
      .get(`/allmsgs/${props.reqId}/${props.userId}/`)
      .then((response) => {
        setAllChats(response.data);
      })
      .catch((err) => console.log(err));
  };

  const sendMessage = () => {
    if (msg) {
      const obj = {
        request_id: props.reqId,
        sender_id: props.userId,
        user_id: props.userId,
        receiver_id: "1e1cde20-d813-11ed-b34f-1d28bd000000",
        message: msg,
        createdAt: new Date(),
      };
      saveMsgToDB(obj);
    }
  };

  const saveMsgToDB = (data) => {
    axiosClient
      .post(`/sendmsg`, data)
      .then((response) => {
        console.log(response.data);
        setAllChats((prev) => [...prev, data]);
        props.sendMsgSentSocket(data);
        setMsg("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      msg && sendMessage();
    }
  };
  // console.log(props.occasion);

  return (
    <Fragment>
      <div
        className={
          "wrapper__chatlist-detail bg__black-3 " +
          (props.ChatListDetail ? "active" : "")
        }
      >
        <div className="container mb-0">
          <div className="top text-center">
            <div onClick={() => props.onCLickModalChatListDetail()}>
              <BackIcon />
            </div>
            <div className="medium font__size--14 text-uppercase">RequestS</div>
            <div onClick={() => props.onCLickModalChatListDetail()}>
              <CloseXIcon />
            </div>
          </div>
        </div>
        <div className="title__chat mb-4">
          <div className="container">
            <div className="user">
              <div className="profile">
                <img
                  // src="./../images/Avatar (1).png"
                  src={`https://ui-avatars.com/api/?name=${
                    props.selectedRequest?.msg_obj
                      ? JSON.parse(props.selectedRequest.msg_obj).occasion
                      : props.occasion ?? "Business"
                  }`}
                  title={`${
                    props.selectedRequest?.msg_obj
                      ? JSON.parse(props.selectedRequest.msg_obj).occasion
                      : props.occasion ?? "Business"
                  } booking request`}
                  className="flex-shrink-0"
                  alt=""
                />
                <h5 className="mb-0 ml-2 bold font__size--14 clamp__1">
                  {props.selectedRequest?.msg_obj
                    ? JSON.parse(props.selectedRequest.msg_obj).occasion
                    : props.occasion ?? "Business"}{" "}
                  booking request
                </h5>
              </div>
              {/* <div className="ml-2 flex-shrink-0">
                <EditIcon />
              </div> */}
            </div>
          </div>
        </div>
        <div className="wrapper__box-chat">
          <div className="container">
            {/* <UserTextChat
              setOnClickUpdatedChat={() => props.setOnClickUpdatedChat()}
              edited={true}
            />
            <AdminTextChat /> */}
            {/* <UserTextChat
              setOnClickUpdatedChat={() => props.setOnClickUpdatedChat()}
              edited={false}
              updated={true}
            /> */}
            {allChats.map((chat) => {
              return chat.sender_id === props.userId ? (
                <UserTextChat key={chat.id} data={chat} edited={false} />
              ) : (
                <AdminTextChat key={chat.id} chat={chat} />
              );
            })}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <div className="wrapper__type-chat">
          <div className="container">
            <div className="field d-flex align-items-center">
              <input
                type="text"
                placeholder="Start typing....."
                className="medium font__size--14 color__white"
                onChange={(e) => setMsg(e.target.value)}
                value={msg}
                onKeyDown={handleKeyDown}
              />
              <div className="send flex-shrink-0 ml-2" onClick={sendMessage}>
                <SendIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatListDetail;
