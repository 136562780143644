import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import MakeRequest from "../../component/Nav/MakeRequest";
import TopProfile from "../../component/Nav/TopProfile";
import SecSearch from "../../component/Field/Search";
import CardReservation from "../../component/Card/CardReservation";
import FistTime from "../../component/Modal/First/FistTime";
import RequestStep from "../../component/Modal/Request/Modal/RequestStep";
import ChatStep from "../../component/Chat/ChatStep";
import { UserContext } from "../../utils/userContext";
import { Navigate } from "react-router-dom";
import axiosClient from "../../utils/apiClient";
import { io } from "socket.io-client";
import { useRef } from "react";
import InstallPWAModal from "../../component/Modal/PWAInstall/pwa-install";

export default class HomeRequest extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      messageNotification: true,
      // MessageCount: 3,
      request: true,
      clickRequet: false,
      statusClick: false,
      clickChat: false,
      typeChoose: "",
      location: "",
      search: "",
      occasion: "",
      selectDate: "",
      selectTime: "",
      selectSize: "",
      cuisine: "",
      upcomingReservationsArr: [],
      reqArr: [],
      showInstallModal: false, //PWA
    };
    this.socket = React.createRef();
    this.installPromptEvent = null;
    this.handleBeforeInstallPrompt = this.handleBeforeInstallPrompt.bind(this);
  }

  componentDidMount() {
    let user = this.context.user;
    if (!this.context.user || Object.keys(this.context.user).length === 0) {
      user = localStorage.getItem("user");
      console.log(JSON.parse(user));
      if (!user || Object.keys(JSON.parse(user)).length === 0)
        window.location = "/login";
      user = JSON.parse(user);
    }
    this.userData = user;

    console.log("User", this.socket);
    this.socket && this.setSockets();
    this.fetchUpcomingReservations(user.id);
    this.fetchRequests(user.id);

    //PWA
    window.addEventListener(
      "beforeinstallprompt",
      this.handleBeforeInstallPrompt
    );
  }

  handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    this.installPromptEvent = event;
    this.setState({ showInstallModal: true });
  };
  handleCloseModal = () => {
    this.setState({ showInstallModal: false });
  };
  handleInstallPWA = () => {
    if (this.installPromptEvent) {
      this.installPromptEvent.prompt();

      this.installPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        this.installPromptEvent = null;
        this.setState({ showInstallModal: false });
      });
    }
  };

  componentWillUnmount() {
    // Remove the event listener
    window.removeEventListener(
      "beforeinstallprompt",
      this.handleBeforeInstallPrompt
    );
  }

  setSockets = () => {
    this.socket.current = io(process.env.REACT_APP_BACKEND_URL);
    // this.socket.current.on("init", function (data) {
    //   console.log("Socket Connection initialized");
    // });
    //when a user login
    this.context.setSocket(this.socket.current);
    this.socket.current.emit("login-user", this.userData?.id);
    this.socket.current.on("incoming-msg", (data) => {
      // console.log("msg added", data);
      // const targetReq = this.state.upcomingReservationsArr.filter(
      //   (x) => x.id == data.req_id
      // );
      this.fetchRequests(this.userData.id);
    });
    this.socket.current.on("new-fulfilled-req", (data) => {
      this.fetchUpcomingReservations(this.userData.id);
      this.fetchRequests(this.userData.id);
    });
  };

  fetchUpcomingReservations(userId) {
    axiosClient
      .get(`/allreqs/${userId}/true`)
      .then((response) => {
        if (response.data.err_msg) {
          this.setState({
            error: response.data.err_msg,
          });
        } else {
          this.setState({ upcomingReservationsArr: response.data });
        }
      })
      .catch((err) => console.log(err));
  }

  setMakeRequest = (e, b) => {
    this.setState({
      [b]: e,
    });
  };

  setOnClickChat = (e) => {
    this.setState({
      clickChat: e === false ? e : !this.state.clickChat,
    });
  };

  setOnClickRequest = (e) => {
    this.setState({
      clickRequet: e === false ? e : !this.state.clickRequet,
    });
  };
  setOnClickStatus = (e) => {
    this.setState({
      statusClick: !this.state.statusClick,
    });
  };

  fetchRequests = (userId) => {
    axiosClient
      .get(`/allreqs/${userId}/`)
      .then((response) => {
        this.setState({ reqArr: response.data });
        // onCLickModalChatRequest();
      })
      .catch((err) => console.log(err));
  };

  render() {
    if (!this.context.user) {
      window.location = "/login";
    }
    const newUnseenChatCount = this.state.reqArr.reduce(
      (a, x) => (x.unseen > 0 ? (a = a + 1) : (a = a + 0)),
      0
    );
    return (
      <Fragment>
        {/* Don't need any onboarding modal at this moment - Rakesh */}
        {/* <FistTime /> */}
        <div className="overflow-hidden position-relative bg__black-3 wrapper__wrap-all-screan-home">
          <div
            className={
              "position-relative z-2 " + (this.state.request ? "scroller" : "")
            }
          >
            <TopProfile />

            {this.state.request ? (
              <Fragment>
                <SecSearch />

                <section className="position-relative z-2 pb-80px">
                  <div className="container">
                    <h4 className="medium font__size--16 color__gray-2 mb-4">
                      Upcoming Reservations
                    </h4>

                    {this.state.upcomingReservationsArr.map((item) => (
                      <CardReservation data={item} />
                    ))}
                  </div>

                  {this.state.upcomingReservationsArr.length == 0 && (
                    <Fragment>
                      <img
                        src="./../images/Group 33856.png"
                        className="images__bg-wrap"
                        alt=""
                      />
                      <section
                        className="position-relative z-2 pt-0"
                        style={{ marginBottom: "5vh" }}
                      >
                        <div className="container">
                          <div className="wrapper__wrap-opening text-center">
                            <div className="content">
                              <img
                                src="./../images/Group 33852.png"
                                className="mb-4"
                                alt=""
                              />

                              <h4 className="bold font__size--18 mb-3">
                                Get Started on Your Next Reservation.
                              </h4>
                              <p className="normal font__size--12 color__gray-2">
                                No upcoming reservations? Request one now and
                                let us help you find the perfect restaurant.
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>
                    </Fragment>
                  )}

                  {/* <div className="mb-4">
                      <CardReservation />
                    </div>
                    <div className="mb-4">
                      <CardReservation />
                    </div>
                    <div className="mb-4">
                      <CardReservation />
                    </div>
                    <div className="mb-4">
                      <CardReservation />
                    </div> */}
                </section>
              </Fragment>
            ) : (
              <Fragment>
                <img
                  src="./../images/Group 33856.png"
                  className="images__bg-wrap"
                  alt=""
                />
                <section className="position-relative z-2 pt-0">
                  <div className="container">
                    <div className="wrapper__wrap-opening text-center">
                      <div className="content">
                        <img
                          src="./../images/Group 33852.png"
                          className="mb-4"
                          alt=""
                        />

                        <h4 className="bold font__size--18 mb-3">
                          Get Started on Your Next Reservation.
                        </h4>
                        <p className="normal font__size--12 color__gray-2">
                          No upcoming reservations? Request one now and let us
                          help you find the perfect restaurant.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </Fragment>
            )}

            {/* {this.state.statusClick && ( */}
            <RequestStep
              dataState={this.state}
              setMakeRequest={(e, b) => this.setMakeRequest(e, b)}
              setOnClickRequest={(e) => this.setOnClickRequest(e)}
              setOnClickStatus={() => this.setOnClickStatus()}
              // openChatModal={() => this.setOnClickChat(true)}
              userId={this.userData?.id}
              MakeRequest={this.state.clickRequet}
              statusClick={this.state.statusClick}
              sendMsgSentSocket={(data) =>
                this.socket.current.emit("msg-sent", data)
              }
              reqArr={this.state.reqArr}
              resetPrevSelection={() =>
                this.setState({
                  typeChoose: "",
                  location: "",
                  search: "",
                  occasion: "",
                  place: "",
                  selectDate: "",
                  selectTime: "5:00 PM",
                  selectSize: "",
                  cuisine: "",
                  statusClick: false,
                })
              }
            />
            {/* )} */}

            <ChatStep
              dataState={this.state}
              userId={this.userData?.id}
              setOnClickChat={(e) => this.setOnClickChat(e)}
            />

            <MakeRequest
              to={"/homepage/upcoming"}
              Alert={this.state.Alert}
              MessageCount={newUnseenChatCount}
              messageNotification={this.state.messageNotification}
              setOnClickRequest={() => {
                this.setOnClickRequest();
                this.setOnClickStatus();
              }}
              userId={this.userData?.id}
              setOnClickChat={(e) => {
                this.setOnClickChat(e);
                this.fetchRequests(this.userData.id);
              }}
            />
          </div>
        </div>
        {this.state.showInstallModal && (
          <InstallPWAModal
            show={this.state.showInstallModal}
            onHide={this.handleCloseModal}
            onInstall={this.handleInstallPWA}
          />
        )}
      </Fragment>
    );
  }
}
