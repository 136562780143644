import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import VerificationInput from "react-verification-input";
import Gif from "../../component/Gif/Gif";

import { useHistory } from "react-router-dom";
import axiosClient from "../../utils/apiClient";

// import AWS from 'aws-sdk';

// import twilio from 'twilio';
const accountSid = "ACe4287d6736b868ec1d1f453d0a87e898"; // process.env.TWILIO_ACCOUNT_SID;
const authToken = "1cbc9bf3d7d6249d3919ee24dc237b63"; // process.env.TWILIO_AUTH_TOKEN;

const Code = (props) => {
  const [code, setCode] = useState("");
  // const [VerificationCOde, setVerificationCOde] = useState("");
  const [error, setError] = useState(null);
  // const paramsNumber = useParams();
  const history = useHistory();

  useEffect(() => {
    const style = document.documentElement.style;
    style.setProperty("--height", `${window.innerHeight}px`);
  }, []);

  // console.log(API.graphql(graphqlOperation(listTodos)));
  // useEffect(() => {
  //   // set number phone
  //   setPhone(paramsNumber.phone);

  //   // generate random code
  //   var rand = Math.floor(1000 + Math.random() * 9000);
  //   setVerificationCOde(rand);

  //   // send sms
  //   var params = {
  //     Message: "Centurion's verification code is " + rand,
  //     PhoneNumber: "+" + paramsNumber.phone,
  //   };

  //   fetch(
  //     `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`,
  //     {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Basic ${btoa(`${accountSid}:${authToken}`)}`,
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //       body: new URLSearchParams({
  //         From: "+1 475 267 6223",
  //         To: params.PhoneNumber,
  //         Body: params.Message,
  //       }).toString(),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((response) => console.log(response))
  //     .catch((err) => console.log(err.message));
  // }, []);

  const verifyCode = () => {
    axiosClient
      .post("/validate-beta-user", {
        phone_no: props.phone,
        token: code,
      })
      .then((response) => {
        history.push("/thanks");
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data);
      });
  };

  return (
    <Fragment>
      <div className="overflow-hidden bg__black wrapper__wrap-all-screan">
        <div className="h-100 position-relative d-flex">
          <Gif />

          <div className="position-relative z-5 w-100 wrapper__bg-first align-self-end">
            <div className="container w-100 py-4">
              <div className="text-center mb-5">
                <img src="./../images/dsads.png" className="mb-2" alt="" />
                <h2 className="bold font__size--18 lh-1">
                  Verify Your Phone Number
                </h2>
                <p className="normal font__size--14 mb-0">
                  To complete your registration, we need to verify your phone
                  number . Please enter the 4-digit code we sent to{" "}
                  <span className="color__tosca bold">{props.phone}.</span>
                </p>

                <div className="mt-4">
                  <div className="wrapper__verification">
                    <VerificationInput
                      length={4}
                      onChange={(code) => setCode(code)}
                      classNames={{
                        container: "container",
                        character: "character",
                        characterInactive: "character--inactive",
                        characterSelected: "character--selected",
                      }}
                    />
                    <div className="text-center">
                      <div
                        className="pointer medium font__size--14 d-inline-block mt-3"
                        onClick={props.onRequest}
                      >
                        Send code again
                      </div>
                    </div>
                    {error && (
                      <p className="mb-0 color__red normal font__size--14">
                        {error}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <button
                disabled={!code || code.length <= 3}
                onClick={() => verifyCode()}
                className="bold font__size--16 btn btn__tosca shadow color__black-2 w-100 rounded__20"
              >
                Verify Number
              </button>

              <div className="text-center mt-4">
                <NavLink
                  to="/"
                  className="mb-0 normal font__size--16 color__gray-2 decoration"
                >
                  Back to Home
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Code;
