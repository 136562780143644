import moment from "moment";
import React from "react";
import adminAvatar from "./avatar.jpg";

const AdminTextChat = (props) => {
  return (
    <div className="wrapper__admin-text-chat mb-4">
      <div className="wrap">
        <div className="user">
          {/* <img src="./../images/Avatar (1).png" alt="" /> */}
          <img
            // src={`https://ui-avatars.com/api/?name=Centurion Admin`}
            // title="Centurion Admin"
            src={adminAvatar}
            title="Customer Support"
            alt=""
          />

          <div
            className="text__desc medium font__size--12 ml-2"
            dangerouslySetInnerHTML={{ __html: props.chat.message }}
          ></div>
        </div>
        <div className="d-flex align-items-center info mt-2">
          <span className="normal font__size--12 mr-2">
            {moment(props.chat.createdAt).format("DD-MM-YY h:mm a")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AdminTextChat;
