import React from "react";
import { Fragment } from "react";
import Search from "../Field/Search";
import { BackIcon, CloseXIcon } from "../Svg/Svg";
import ChatList from "./ChatList";
import moment from "moment";

const ChatRequest = (props) => {
  // const chatListData = [
  //     {
  //         img: "./../images/Avatar (1).png",
  //         title: "Business booking request",
  //         desc: "Hi Williams, are you available for a quick call today",
  //         date: "Yesterday, 8:16am",
  //         notif: 1,
  //     },
  //     {
  //         img: "./../images/Avatar (1).png",
  //         title: "Business booking request",
  //         desc: "Hi Williams, are you available for a quick call today",
  //         date: "Yesterday, 8:16am",
  //         notif: 0,
  //     },
  //     {
  //         img: "./../images/Avatar (1).png",
  //         title: "Business booking request",
  //         desc: "Hi Williams, are you available for a quick call today",
  //         date: "Yesterday, 8:16am",
  //         notif: 0,
  //     },
  //     {
  //         img: "./../images/Avatar (1).png",
  //         title: "Business booking request",
  //         desc: "Hi Williams, are you available for a quick call today",
  //         date: "Yesterday, 8:16am",
  //         notif: 0,
  //     },
  //     {
  //         img: "./../images/Avatar (1).png",
  //         title: "Business booking request",
  //         desc: "Hi Williams, are you available for a quick call today",
  //         date: "Yesterday, 8:16am",
  //         notif: 0,
  //     },
  //     {
  //         img: "./../images/Avatar (1).png",
  //         title: "Business booking request",
  //         desc: "Hi Williams, are you available for a quick call today",
  //         date: "Yesterday, 8:16am",
  //         notif: 0,
  //     },
  //     {
  //         img: "./../images/Avatar (1).png",
  //         title: "Business booking request",
  //         desc: "Hi Williams, are you available for a quick call today",
  //         date: "Yesterday, 8:16am",
  //         notif: 0,
  //     },
  // ]

  return (
    <Fragment>
      <div
        className={
          "wrapper__modal-chat-request bg__black-3 " +
          (props.chatRequest ? "active" : "")
        }
      >
        <div className="container mb-0">
          <div className="top text-center">
            <div onClick={() => props.onCLickModalChatRequest()}>
              <BackIcon />
            </div>
            <div className="medium font__size--14 text-uppercase">RequestS</div>
            <div onClick={() => props.onCLickModalChatRequest()}>
              <CloseXIcon />
            </div>
          </div>
        </div>
        <div className="mb-4">
          <Search />
        </div>
        <div className="wrapper__chat-wrap-list">
          {props.chatReqArr.map((data) => {
            return (
              <div
                onClick={() => {
                  props.onCLickModalChatListDetail();
                  props.setChatRequest(data.id);
                }}
              >
                <ChatList
                  data={{
                    // img: "./../images/Avatar (1).png",
                    img: `https://ui-avatars.com/api/?name=${
                      data?.msg_obj
                        ? JSON.parse(data.msg_obj).occasion
                        : "Business"
                    }`,
                    title: `${
                      data?.msg_obj
                        ? JSON.parse(data.msg_obj).occasion
                        : "Business"
                    } booking request`,
                    desc: data.message.substring(20) + "...",
                    date: data.createdAt
                      ? moment(data.createdAt).format("DD-MM-YY h:mm a")
                      : null,
                    notif: data.unseen,
                  }}
                />
              </div>
            );
          })}

          {props.chatReqArr.length == 0 && (
            <p className="d-flex justify-content-center mt-4">No Data Found!</p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ChatRequest;
