import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { BackIcon, LocationIcon, SearchIcon } from "../../../../Svg/Svg";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
// import GooglePlacesAutocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const Location = (props) => {
  const [Type, setType] = useState(2);
  const [Location, setLocation] = useState("");
  const [place, setPlace] = useState("");

  const [Search, setSearch] = useState("");

  const Radius = 20000;

  useEffect(() => {
    props.setMakeRequest(Location?.name, "location");
    props.setMakeRequest(place, "place");
  }, [Location, place]);

  return (
    <Fragment>
      <div className="text-center general location">
        <h4 className="bold font__size--18">
          Where do you want a reservation?
        </h4>
        <p className="normal font__size--16 color__gray-2 mb-3">
          Tell us your ideal location.....{" "}
        </p>

        <div className="mb-4">
          <div className="wrapper__filed-general">
            {/* <LocationIcon />
                  <input
                    type="text"
                    placeholder="Location"
                    className="form-control medium font__size--14 color__white ml-2 w-100"
                    onChange={(e) => setLocation(e.target.value)}
                  /> */}
            <Autocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
              style={{ width: "98%" }}
              onPlaceSelected={(place) => {
                const placeObj = {
                  name: place.formatted_address,
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                  viewport: place.geometry.viewport,
                };
                console.log("placeObj", place);
                setLocation(placeObj);
              }}
              options={{
                types: ["(cities)"],
                // componentRestrictions: { country: "ru" },
              }}
              // defaultValue="Amsterdam"
            />
          </div>
          {props.dataState.typeChoose == 2 ? (
            <div className="wrapper__filed-general mt-3">
              {/* <SearchIcon />
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control medium font__size--14 color__white ml-2 w-100"
                      onChange={(e) => setSearch(e.target.value)}
                    /> */}
              {/* {("====", process.env.GOOGLE_MAPS_API)} */}

              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                className="hahaha"
                placeholder="Search for restaurants..."
                // onPlaceSelected={(place) => {
                //   console.log(place);
                //   setPlace(place.formatted_address);
                // }}
                autocompletionRequest={{
                  location: { lat: Location.lat, lng: Location.lng },
                  radius: Radius,
                  type: ["restaurant", "bar"],
                }}
                selectProps={{
                  onChange: setPlace,
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="d-flex align-items-center">
          <div
            onClick={() => props.setActiveModal(1)}
            className="arrow flex-shrink-0 pointer"
          >
            <BackIcon />
          </div>
          <div
            onClick={() =>
              props.dataState.typeChoose == 2
                ? Location && place != ""
                  ? (props.setActiveModal(3),
                    props.updateReqData("location", Location),
                    props.updateReqData("place", place))
                  : ""
                : Location
                ? (props.setActiveModal(3),
                  props.updateReqData("location", Location))
                : ""
            }
            className={
              "btn btn__tosca btn__select w-100 normal font__size--14 " +
              (props.dataState.typeChoose == 2
                ? Location && place != ""
                  ? "active"
                  : ""
                : Location
                ? "active"
                : "")
            }
          >
            Next:{" "}
            <b>{props.dataState.typeChoose == 2 ? "Occasion" : "Occasion"}</b>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Location;
