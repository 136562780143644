import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import {
    BackIcon,
    BusinessIcon,
    CasualIcon,
    RomanticIcon,
    SportIcon,
} from '../../../../Svg/Svg';

const SelectSize = (props) => {
    const [Select, setSelect] = useState("");
    const [Size, setSize] = useState([
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
    ]);
    useEffect(() => {
        props.setMakeRequest(Select, "selectSize");
    }, [Select]);
    return (
        <Fragment>
            <div className="text-center general size">
                <h4 className="bold font__size--18">Party Size?</h4>
                <p className="normal font__size--16 color__gray-2 mb-4">
                    Select the amount of people in party
                </p>

                <div className="mb-4 list__size">
                    {Size.map((obj) => [
                        <div
                            className={"items " + (Select == obj ? "active" : "")}
                            onClick={() => setSelect(obj)}
                        >
                            {obj}
                        </div>,
                    ])}
                </div>

                <div className="d-flex align-items-center">
                    <div
                        onClick={() =>
                            props.setActiveModal(5)
                        }
                        className="arrow flex-shrink-0 pointer"
                    >
                        <BackIcon />
                    </div>
                    <div
                        onClick={() => {
                            // props.dataState.typeChoose == 2
                            //   ? props.onCLickSwitchModal("SelectSizeToReview")
                            //   : props.onCLickSwitchModal("SelectSizeToCuisine");
                            props.setActiveModal(7);
                            props.updateReqData("noOfPeople", Select);
                        }}
                        className={
                            "btn btn__tosca btn__select w-100 normal font__size--14 " +
                            (Select ? "active" : "")
                        }
                    >
                        {/* Next: <b>Review All Selections</b> */}
                        Next: <b>Cuisine</b>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SelectSize;
