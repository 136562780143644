import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import {
    BackIcon,
    BusinessIcon,
    CasualIcon,
    RomanticIcon,
    SportIcon,
} from '../../../../Svg/Svg';

const Occasion = (props) => {
    const [Select, setSelect] = useState("");
    useEffect(() => {
        props.setMakeRequest(Select, "occasion");
    }, [Select]);

    return (
        <Fragment>
            <div className="text-center general occasion">
                <h4 className="bold font__size--18">What’s the Occasion?</h4>
                <p className="normal font__size--16 color__gray-2 mb-3">
                    Help us tailor your experience.
                </p>

                <div className="mb-4 list__wrap">
                    <div
                        className={
                            "items pointer " + (Select == "Business" ? "active" : "")
                        }
                        onClick={() => setSelect("Business")}
                    >
                        <BusinessIcon />
                        <div className="line"></div>
                        <div className="normal font__size--16 color__gray-2">
                            Business
                        </div>
                    </div>
                    <div
                        className={
                            "items pointer " + (Select == "Casual" ? "active" : "")
                        }
                        onClick={() => setSelect("Casual")}
                    >
                        <CasualIcon />
                        <div className="line"></div>
                        <div className="normal font__size--16 color__gray-2">
                            Casual
                        </div>
                    </div>
                    <div
                        className={
                            "items pointer " + (Select == "Romantic" ? "active" : "")
                        }
                        onClick={() => setSelect("Romantic")}
                    >
                        <RomanticIcon />
                        <div className="line"></div>
                        <div className="normal font__size--16 color__gray-2">
                            Romantic
                        </div>
                    </div>
                    <div
                        className={
                            "items pointer " + (Select == "Sports" ? "active" : "")
                        }
                        onClick={() => setSelect("Sports")}
                    >
                        <SportIcon />
                        <div className="line"></div>
                        <div className="normal font__size--16 color__gray-2">
                            Sports
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <div
                        onClick={() =>
                            props.setActiveModal(2)
                        }
                        className="arrow flex-shrink-0 pointer"
                    >
                        <BackIcon />
                    </div>
                    <div
                        onClick={() => (
                            props.setActiveModal(4),
                            props.updateReqData("occasion", Select)
                        )}
                        className={
                            "btn btn__tosca btn__select w-100 normal font__size--14 " +
                            (Select ? "active" : "")
                        }
                    >
                        Next: <b>Date Selection</b>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Occasion;
