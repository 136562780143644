import React, { useEffect, useState } from "react";
import { CalendarIcon, StarIcon, TimeIcon, TwoUserIcon } from "../Svg/Svg";
import axios from "axios";
import moment from "moment";

const CardReservation = (props) => {
  const data = props.data?.msg_obj ? JSON.parse(props.data.msg_obj) : null;
  const [imgUrl, setImgUrl] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  // console.log("@@@@@@@@@", bookingData);

  const getImgFromApi = async (placeRef) => {
    if (placeRef) {
      const url = `${process.env.REACT_APP_BACKEND_URL}/getImg/${placeRef}`;
      const res = await axios.get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      console.log(res.data);
      setImgUrl(res.data);
    }
  };

  useEffect(() => {
    if (props.data.msg_obj) {
      setBookingData(JSON.parse(props.data.msg_obj));
    }
  }, [props.data]);

  useEffect(() => {
    if (bookingData && bookingData.place)
      getImgFromApi(bookingData.place.value?.place_id);
  }, [bookingData]);

  return (
    <div className="wrapper__card-reservation mb-3">
      <div className="head">
        <div className="top">
          <CalendarIcon color={"#00E6F6"} />
          <span className="ml-2 medium font__size--12 color__white">
            Reservation on{" "}
            <strong>
              {moment(bookingData?.date, "DD-MM-YYYY").format("DD MMM")}
            </strong>
          </span>
        </div>
        <hr />
        <div className="content">
          <div className="desc">
            <div className="cover flex-shrink-0">
              <img src={imgUrl} alt="" />
            </div>
            <div className="ml-3">
              <h5 className="bold font__size--12">
                {" "}
                {bookingData?.place?.label}
              </h5>
              <div className="my-2 normal font__size--10 color__tosca">
                {bookingData?.place.value &&
                  bookingData?.place.value?.types
                    .slice(0, 2)
                    .map((x) => (
                      <div className="my-2 tag normal font__size--10 color__tosca mr-2">
                        {x}
                      </div>
                    ))}
              </div>
              <p className="mb-0 normal lh-1 font__size--11 color__gray-2">
                {
                  bookingData?.place.value?.structured_formatting
                    ?.secondary_text
                }{" "}
              </p>
            </div>
          </div>
          <div className="star flex-shrink-0">
            <StarIcon color={"#00E6F6"} />
            <StarIcon color={"#00E6F6"} />
            <StarIcon color={"#00E6F6"} />
            <StarIcon color={"#00E6F6"} />
            <StarIcon color={"#00E6F6"} />
          </div>
        </div>
      </div>
      <div className="bottom d-flex align-items-center justify-content-center">
        <div className="items">
          <CalendarIcon width={12} height={12} color={"#BCFF5E"} />{" "}
          <span
            className="ml-1 normal font__size--6"
            style={{ color: "#BCFF5E" }}
          >
            {data.date}
          </span>
        </div>
        <div className="items">
          <TimeIcon width={12} height={12} color={"#00E6F6"} />{" "}
          <span
            className="ml-1 normal font__size--6"
            style={{ color: "#00E6F6" }}
          >
            {data.time}
          </span>
        </div>
        <div className="items">
          <TwoUserIcon width={12} height={12} color={"#F6CF00"} />{" "}
          <span
            className="ml-1 normal font__size--6"
            style={{ color: "#F6CF00" }}
          >
            {data.noOfPeople}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardReservation;
